import React from "react"
import { graphql, navigate, StaticQuery } from "gatsby"
import "./home.css"

export default () => (
  <StaticQuery
    query={graphql`
      query MyQuery {
        allContentfulTextAudio(
          limit: 6
          sort: { fields: createdAt, order: DESC }
        ) {
          edges {
            node {
              id
              slug
              titel
              titelbild {
                fluid(maxWidth: 600, quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="feed">
        {data.allContentfulTextAudio.edges.map(edge => (
          <div
            key={edge.node.id}
            className="card"
            onClick={() => navigate(`/text-audio/${edge.node.slug}`)}
            style={{
              backgroundImage: `url(${edge.node.titelbild.fluid.src})`,
            }}
          >
            <div className="card__title">
              <p>{edge.node.titel}</p>
            </div>
          </div>
        ))}
      </div>
    )}
  />
)
