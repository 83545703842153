import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout.js"
import Nav from "../components/nav.js"
import Home from "../components/home.js"
import SEO from "../components/seo.js"
import "./index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Nav />
    <div className="header__hero"></div>
    <h2 style={{fontFamily: "Muli, sans-serif"}}>Momentan sind wir vor allem auf Instagram aktiv <a href="https://www.instagram.com/gruppe_distanz_/">@gruppe_distanz_</a></h2>
    <Home />
    <div className="mehr__box">
      <Link to="/text-audio" className="mehr">
        Mehr Inhalte
      </Link>
    </div>
  </Layout>
)

export default IndexPage
